import React, { useState, useEffect } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'

function InfoAlert({ showAlert, setAlert }) {
    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(showAlert)
    }, [showAlert]);

    const closeAlert = () => {
        setAlert(!show)
    }

    return (
        <div className="infoAlertMessage">
            {show ?
                <Alert variant="info" show={show} style={{ zIndex: 100, marginLeft: '15%', width: '75%' }} >
                    <Alert.Heading style={{ textAlign: 'center', fontSize: '25px' }} >Download Started</Alert.Heading>
                    <p style={{ textAlign: 'center', fontSize: '20px' }}>Large files may take some time to download, if no download happens contact us at support@bioinfox</p>
                    <Button variant='info' onClick={closeAlert} >Close</Button>
                </Alert> : null}
        </div>
    )
}

export default InfoAlert