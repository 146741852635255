import React from 'react';
import logo from '../assets/BIECloud.png';
import Copyright from '../components/Footer/Copyright';

function LandingPage({ show, closeLanding }) {
  // if (!show) {
  //     // console.log(show);
  //     return null;
  // }

  return (
    <div style={{
      position: 'absolute',
      top: 50,
      left: 0,
      width: "100%",
      height: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      pointerEvents: "all",
      zIndex: 100,
      fontFamily: 'Avenir'
    }}>
      <img src={logo} alt="" style={{
        // width: '20%',
        // height: '20%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
      }} />
      <h3 style={{
        marginTop: '5%',
        alignItems: "center",
        // marginLeft: 'calc(100% - 60%)',
        textAlign: 'center',
        fontFamily: 'Avenir',
        fontSize: 'x-large'
      }}>Welcome to BIE Cloud®</h3>
      <p style={{
        textAlign: 'center',
        alignItems: 'center',
        fontFamily: 'Avenir',
        fontSize: 'medium'
      }}>Cloud-based Bioinformatics Software Services</p>
      {/* <div style={{textAlign: 'center', fontSize: '16px'}}>
                <p>Please click on a card at the top to view Cluster information for selected virus/bacteria.</p>
                <p>You may also select a visualization from the bottom right to see your data visualized using Tableau.</p>
                <p>Please click Enter FoxSeq button to start exploring your data.</p>
            </div> */}
      <button style={{
        backgroundColor: 'rgb(71, 163, 209)', //#ffad33 rgb(255, 115, 0)
        position: 'fixed',
        bottom: '150px',
        right: '45%',
        // left: '100%',
        height: '75px',
        width: '200px',
        fontWeight: 'bold',
        fontSize: '1.3em',
        color: 'black',
        textShadow: '-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white',
        borderRadius: '12px',
        cursor: 'pointer'
      }} onClick={() => closeLanding()}>Enter BIE Cloud®</button>
      <Copyright />
    </div>
  )
}

export default LandingPage
