import React, { useState, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';

// let outsideSetShow;
// export function handleSuccess() {
//     outsideSetShow(true);
//     setTimeout(() => {
//         outsideSetShow(false)
//     }, 3000)
// }

function SuccessAlert({ showSuccess, setAlert }) {
    const [show, setShow] = useState(false);

    // outsideSetShow = setShow

    useEffect(() => {
        setShow(showSuccess);
    }, [showSuccess]);

    const handleSuccess = () => {
        setAlert(!show);
    }

    return (
        <div className="successAlertMessage">
            {show ?
                <Alert variant="success" show={show} style={{ zIndex: 100, marginLeft: '15%', width: '75%' }} >
                    <Alert.Heading style={{ textAlign: 'center', fontSize: '25px' }} >Successfully Uploaded</Alert.Heading>
                    <p style={{ textAlign: 'center', fontSize: '20px' }}>File has been successfully uploaded to AWS</p>
                    <Button variant='success' onClick={handleSuccess} >Close</Button>
                </Alert> : null}
        </div>
    )
}

export default SuccessAlert;