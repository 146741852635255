import React, { useRef, useEffect } from 'react'
// import tableau from 'tableau-api';

const { tableau } = window;

function Tableau({ show, url }) {
    // const [display, setDisplay] = useState(show);

    const ref = useRef(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const initViz = () => {
        new tableau.Viz(ref.current, url, {
            device: 'desktop',
            toolbarPosition: 'TOP',
            width: '100%',
            height: '81vh'
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(initViz, []);

    if (!show) {
        return null;
    }

    return (
        <div ref={ref} />
    )
}

export default Tableau
