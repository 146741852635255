import React, { useState, useEffect } from 'react'
import './DownloadFiles.css';
import Button from 'react-bootstrap/Button';
import { getFastaFile, getTreFile, getTemplates, checkFile, downloadFiles, downloadMeta, checkMeta, downloadList } from '../../../Helpers';
import { useAuth0 } from '@auth0/auth0-react';
import InfoAlert from '../../Alerts/InfoAlert'
import Spinner from 'react-bootstrap/Spinner'
import DownloadList from './DowloadList'
let outsideSetFasta;
let outsideSetTre;
let outsideSetFiltered;

export function checkFileFasta(bool) {
    outsideSetFasta(bool);
}
export function checkFileTre(bool) {
    outsideSetTre(bool);
}
export function checkFileFiltered(bool) {
    outsideSetFiltered(bool);
}
let outsideSetAdditonal;
export function showAdd(bool) {
    outsideSetAdditonal(bool);
}
let outsideSetStandard
export function showStandard(bool) {
    outsideSetStandard(bool);
}
let outsideSetSequence
export function showSequence(bool) {
    outsideSetSequence(bool);
}
let outsideSetProduction
export function showProduction(bool) {
    outsideSetProduction(bool);
}
let outsideSetAnalysis
export function showAnalysis(bool) {
    outsideSetAnalysis(bool);
}

function DownloadFiles({ show, hospital, admin, sub }) {
    const [fasta, setFasta] = useState(false);
    const [tre, setTre] = useState(false);
    const [filtered, setFiltered] = useState(false);
    // const [usaGIS, setUsaGIS] = useState(true);
    // const [globalGIS, setGlobalGIS] = useState(true);
    const [additional, setAdditional] = useState(false)
    const [standard, setStandard] = useState(false);
    const [sequence, setSequence] = useState(false);
    const [production, setProduction] = useState(false);
    const [analysis, setAnalysis] = useState(false);
    const [alert, setAlert] = useState(false);
    const [loading, setLoading] = useState(true);
    const [available, setAvailable] = useState([])
    const { getAccessTokenSilently } = useAuth0();

    outsideSetTre = setTre;
    outsideSetFasta = setFasta;
    outsideSetFiltered = setFiltered;
    outsideSetAdditonal = setAdditional
    outsideSetStandard = setStandard;
    outsideSetSequence = setSequence;
    outsideSetProduction = setProduction;
    outsideSetAnalysis = setAnalysis;

    useEffect(() => {
        // checkAddFile(hospital);
        // checkStandard(hospital);
        let token;
        (async () => {
            token = await getAccessTokenSilently();
            // console.log(token)
            checkFile(hospital, token, setLoading, sub);
            downloadList(hospital, token,setAvailable, sub);
            checkMeta(hospital, token, setLoading)
        })()
        // checkSequence(hospital);
    }, [hospital]);

    if (!show) {
        return <h3>Please select a Project/Sub-Project</h3>;
    }

    // const downloadSampleTemp = async () => {
    //     let token = await getAccessTokenSilently();
    //     getTemplates(hospital, token, 'sample', 'samples')
    // }

    // const downloadSeqTemp = async () => {
    //     let token = await getAccessTokenSilently();
    //     getTemplates(hospital, token, 'sequence', 'samples')
    // }

    const downloadFilesAll = async (bucket, key) => {
        let token = await getAccessTokenSilently();
        downloadFiles(hospital, token, bucket, key, sub);
        setAlert(true);
    }

    const downloadMetadata = async (bucket, key) => {
        let token = await getAccessTokenSilently();
        downloadMeta(hospital, token, bucket, key);
        setAlert(true);
    }

    return (
        <div className="downloadFiles" >
            {loading ?
                <Spinner animation='border' role="status" variant='primary' /> : null
            }
            <InfoAlert
                showAlert={alert}
                setAlert={setAlert}
            />
            {/* <h3>Download Files</h3> */}
            {/* <div className="projectDown">
                <h3 className="title">Download Project Analysis Files</h3>
                {(fasta === false && tre === false && filtered === false) || admin === false ?
                    <p className="helpful">Download options will appear if you have the files in your Project</p> : null
                }
                {fasta === true && admin === true ?
                    <Button className="download-btn" variant="primary" onClick={() => downloadFilesAll('output', 'fasta')} >Download Fasta File</Button> : null
                }
                {filtered === true && admin === true ?
                    <Button className="download-btn" variant='primary' onClick={() => downloadFilesAll('output', 'filtered')} >Download HQ-Fasta File</Button> : null
                }
                {tre === true && admin === true ?
                    <Button className="download-btn" variant="primary" onClick={() => downloadFilesAll('output', 'tre')} >Download Tree File</Button> : null
                }
            </div> */}
            <div className="projectMeta">
                <h3 className="title">Download Sample Metadata File</h3>
                {standard === false || admin === false ?
                    <p className="helpful">Download options will appear if you have the files in your Project</p> : null
                }
                {standard === true && admin === true ?
                    <Button className="hosp_temp" variant="primary" onClick={() => downloadMetadata('meta', 'sample')} >Sample Metadata</Button> : null
                    // <Button className="hosp_temp" variant="primary" onClick={() => getHospTemp(hospital)} >Sample Metadata (Standard)</Button> : null
                }

            </div>
            {/* <div className="projectMeta" >
                <h3 className="title">Download Sample Metadata File (Custom)</h3>
                {additional === false || admin === false ?
                    <p className="helpful">Download options will appear if you have the files in your Project</p> : null
                }
                {additional === true && admin === true ?
                    <Button className="add-btn" variant="primary" onClick={() => downloadFilesAll('samples', 'custom')} >Sample Metadata (Custom)</Button> : null
                }
            </div> */}
            <div className="sequenceMeta" >
                <h3 className="title">Download Sequence Metadata File</h3>
                {sequence === false || admin === false ?
                    <p className="helpful">Download options will appear if you have the files in your Project</p> : null
                }
                {sequence === true && admin === true ?
                    <Button className="download-btn" variant="primary" onClick={() => downloadMetadata('meta', 'sequence')} >Sequence Metadata</Button> : null
                }
            </div>
            <div className="productionMeta" >
                <h3 className="title">Download Production Metadata File</h3>
                {production === false || admin === false ?
                    <p className="helpful">Download options will appear if you have the files in your Project</p> : null
                }
                {production === true && admin === true ?
                    <Button className="download-btn" variant="primary" onClick={() => downloadMetadata('meta', 'production')} >Production Metadata</Button> : null
                }
            </div>
            <div className="analysisMeta" >
                <h3 className="title">Download Analysis Metadata File</h3>
                {analysis === false || admin === false ?
                    <p className="helpful">Download options will appear if you have the files in your Project</p> : null
                }
                {analysis === true && admin === true ?
                    <Button className="download-btn" variant="primary" onClick={() => downloadMetadata('meta', 'analysis')} >Analysis Metadata</Button> : null
                }
            </div>
            <DownloadList 
                available={available}
                downloadFilesAll={downloadFilesAll}
            />
            {/* add new download template function and pass proper parameters */}
            {/* <div className="template">
                <h3 className="title">Download Template Metadata File</h3>
                <Button className="template-btn" variant="primary" onClick={downloadSampleTemp} >Sample Metadata (Template)</Button>
                <Button className="template-btn" variant="primary" onClick={downloadSeqTemp} >Sequence Metadata (Template)</Button>
            </div> */}
            {/* <div className="gisaidDown">
                <h3 className="title">Download GISAID Files</h3>
                <p className='helpful' >Contact us at support@bioinfox.com for GISAID files</p>
                {usaGIS ? 
                    <Button className="download-btn" variant="primary" onClick={getGisaidUsa} >Download GISAID Metadata (USA)</Button>
                    : null
                }
                {globalGIS ?
                    <Button className="download-btn" variant="primary" onClick={getGisaidGlobal} >Download GISAID Metadata (global)</Button>
                    : null
                }
            </div> */}
        </div>
    )
}

export default DownloadFiles;