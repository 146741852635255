import React from 'react'
import './QuickStart.css';
import { Icon } from 'semantic-ui-react';
import menuIcon from "../../../assets/ic_menu_black_24px.svg";
import userIcon from "../../../assets/user-icon.svg";

function QuickStart({ show }) {

    if (!show) {
        return null;
    }

    return (
        <div className="quickStart">
            <h3 className="qs-title">Welcome to BIE-Cloud!</h3>
            <div className="projectHelp" >
                <Icon size="big" name="product hunt" />
                <p className="quick" style={{ fontWeight: 'bold', fontSize: '30px' }}>Please select your Project with this icon in the upper right of the screen by your username/email, this will allow you to upload/download files</p>
            </div>
            <div className="homeHelp">
                <Icon size="big" name="home" />
                <p className="quick">Click the <b>Home</b> icon at anytime to return to this screen</p>
            </div>
            {/* <div className="menuHelp" >
                <img src={menuIcon} alt="menu" className="menu" />
                <p className="quick">Click the <b>Pathogen Menu</b> in the upper left of the screen to switch between Virus, Bacteria and Fungi</p>
            </div> */}
            <div className="uploadHelp" >
                <Icon size="big" name="cloud upload" />
                <p className="quick">Click on the <b>Upload Files</b> icon in the lower left of the screen to upload files for your projects to AWS</p>
            </div>
            <div className="contactHelp" >
                <Icon size="big" name="help circle" />
                <p className="quick">Click the <b>Contact Us</b> button in the bottom left corner of the screen if you are having difficulty to send an email about your problem to the Support Team</p>
            </div>
            {/* <div className="tableauHelp" >
                <Icon size="big" name="globe" />
                <p className="quick">Additional dashboards are available along the bottom right of the screen</p>
            </div> */}
            <div className="tableau_p" >
                <Icon size="big" name="quora" />
                <p className="quick" style={{ fontWeight: 'bold' }}>Click the Q Icon in the bottom right to open the QuickStart dashboard before exploring your own project</p>
            </div>
            <div className="userHelp">
                <img src={userIcon} alt="user" className="user-help" />
                <p className="quick" >Click User Icon in the top right of the screen to display the <b>Log Out</b> button</p>
            </div>
            <br></br>
            <p className="quick" style={{ fontFamily: 'Avenir', textAlign: 'left', marginBottom: '8%' }}>BIE-Cloud requires a double authentication procedure to validate the associated Tableau Online license; please enter in your user and password information again when prompted</p>
        </div>
    )
}

export default QuickStart;