import React, { useState, useEffect, useRef, useCallback } from 'react'
import './App.css';
import LandingPage from './pages/LandingPage';
import Header from './components/Header/Header';
// import { dataTypes } from './constants/Datatypes';
import { useAuth0 } from '@auth0/auth0-react';
import Footer from './components/Footer/Footer';
import Pages from './pages/Pages';
import { getPermissions } from './Helpers';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function App() {
  const { loginWithRedirect, isAuthenticated, logout, getAccessTokenSilently } = useAuth0();
  const [dataType, setDataType] = useState('Virus');
  const [project, setProject] = useState(null);
  const [viz, setViz] = useState('home');
  const [admin, setAdmin] = useState();
  const [perm, setPerm] = useState()

  const prevProject = usePrevious(project);
  // const prevDataType = usePrevious(dataType);

  useEffect(() => {
    if (prevProject !== project) {
      // console.log(project)
      resetViz()
    }
  }, [project, prevProject])


  const closeLanding = () => {
    loginWithRedirect();
  }

  const projectSelected = (role) => {
    // console.log(role);
    setProject(role)
    setAdmin(role.admin)
    setViz('home');
  }

  const useDataType = (selection) => {
    setDataType(selection)
  }

  const changeViz = (vis) => {
    // console.log('clicked to change visual')
    console.log(vis)
    setViz(vis);
  }

  const resetViz = () => {
    // console.log('got called, did it switch tho')
    setViz('home');
  }


  if (!isAuthenticated) {
    return <LandingPage
      closeLanding={closeLanding}
    />
  }

  if (isAuthenticated) {
    // console.log('Signed in but not seeing the app')
  }

  return (
    <React.Fragment>
      <Header
        // dataTypes={dataTypes}
        logout={logout}
        useDataType={useDataType}
        initDataType={dataType}
        projectSelected={projectSelected}
        resetViz={resetViz}
      />
      <Pages
        viz={viz}
        project={project}
      />
      <Footer
        setViz={changeViz}
        viz={viz}
        dataType={dataType}
      />
    </React.Fragment>
  )
}

export default App;
