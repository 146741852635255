import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react'
import 'semantic-ui-css/semantic.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'

ReactDOM.render(
  <Auth0Provider
    domain="bie.us.auth0.com"
    clientId="P1DEVIsUrEiUFIAHptOEShxBFy0ORQmx"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: 'https://bie-cloud.com'
    }}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();