import React, { useRef, useState } from 'react'
import { sHospHelp, uploadFiles, uploadLargeFiles } from '../../../Helpers';
import Button from 'react-bootstrap/Button';
// import Dropdown from 'react-bootstrap/Dropdown';
// import ProgressBar from 'react-bootstrap/ProgressBar'
import './UploadFiles.css';
import SuccessAlert from '../../Alerts/SuccessAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';
import { useAuth0 } from '@auth0/auth0-react'


function UploadFiles({ show, hospital }) {
    const fileRef = useRef({});
    const [selector, setSelector] = useState('Files');
    // const [fastqName, setFastqName] = useState('');
    // const [fastaName, setFastaName] = useState('');
    const [clientName, setClientName] = useState('');
    const [addName, setAddName] = useState('');
    // const [testName, setTestName] = useState('');
    // const [progress, setProgress] = useState(0)
    // const [showProgress, setShowProgress] = useState(false)
    const [alert, setAlert] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [seqMeta, setSeqMeta] = useState('');
    const [prodMeta, setProdMeta] = useState('');
    const [anaMeta, setAnaMeta] = useState('');
    const { getAccessTokenSilently } = useAuth0()

    if (!show) {
        return null
    };

    const fileClientSubmit = async (event) => {
        event.preventDefault();
        let sHosp = sHospHelp(hospital);
        let token = await getAccessTokenSilently();
        let file = fileRef.current['client'].files;
        // console.log(file);
        let key = `${hospital}/SAMPLE_METADATA_${sHosp}.xlsx`;
        // uploadFiles(file, token, 'samples', key);
        uploadLargeFiles(file, token, 'samples', key)
        setClientName('')
        setAlert(true);
    }

    const fileAddSubmit = async (event) => {
        event.preventDefault();
        let sHosp = sHospHelp(hospital);
        let token = await getAccessTokenSilently();
        let file = fileRef.current['add'].files;
        let key = `${hospital}/CUSTOM_METADATA_${sHosp}.xlsx`;
        // uploadFiles(file, token, 'samples', key);
        uploadLargeFiles(file, token, 'samples', key);
        setAddName('');
        setAlert(true);
    }

    const onChangeClient = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setClientName(e.target.files[0]);
        }
    }

    const onChangeAdd = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setAddName(e.target.files[0]);
        }
    }

    const onChangeSeqMeta = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSeqMeta(e.target.files[0]);
        }
    }

    const onChangeProdMeta = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setProdMeta(e.target.files[0]);
        }
    }

    const onChangeAnaMeta = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setAnaMeta(e.target.files[0]);
        }
    }

    const fileSeqMetaSubmit = async (event) => {
        event.preventDefault();
        let sHosp = sHospHelp(hospital);
        let token = await getAccessTokenSilently()
        let file = fileRef.current['seqMeta'].files;
        let key = `${hospital}/SEQUENCE_METADATA_${sHosp}.xlsx`;
        // uploadFiles(file, token, 'samples', key);
        uploadLargeFiles(file, token, 'samples', key);
        setSeqMeta('');
        setAlert(true);
    }

    const fileProdMetaSubmit = async (event) => {
        event.preventDefault();
        let sHosp = sHospHelp(hospital);
        let token = await getAccessTokenSilently();
        let file = fileRef.current['prodMeta'].files;
        let key = `${hospital}/PRODUCTION_METADATA_${sHosp}.xlsx`;
        uploadLargeFiles(file, token, 'samples', key);
        setProdMeta('');
        setAlert(true);
    }

    const fileAnaMetaSubmit = async (event) => {
        event.preventDefault();
        let sHosp = sHospHelp(hospital);
        let token = getAccessTokenSilently();
        let file = fileRef.current['anaMeta'].files;
        let key = `${hospital}/ANALYSIS_METADATA_${sHosp}.xlsx`
        uploadLargeFiles(file, token, 'samples', key);
        setAnaMeta('');
        setAlert(true);
    }

    return (
        <div className="upload" >
            <SuccessAlert
                showSuccess={alert}
                setAlert={setAlert}
            />
            <ErrorAlert
                showError={alert2}
                setAlert={setAlert2}
            />
            {/* <h3 style={{ fontFamily: "Avenir", fontWeight: 'bold' }}>{`Upload Raw Fastq ${selector} to Pipeline`}</h3>
            <div className="selector_drop"> */}
                {/* <Dropdown className="selector">
                    <Dropdown.Toggle>{selector}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setSelector('Files')}>Files</Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelector('Folders')}>Folders</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            {selector === 'Files' ? 
                <input className="input_fastq" type="file" accept=".fastq, .gz" multiple
                    ref={el => fileRef.current['fastq'] = el}
                    onChange={onChange}
                /> : 
                <input className="input_fastq" type="file" accept=".fastq, .gz" multiple webkitdirectory="true" directory="true"
                    ref={el => fileRef.current['fastq'] = el}
                    onChange={onChange}
                />
            } */}
                {/* <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['fastq'].click()}>{`Choose Fastq ${selector}`}</Button>
            <input type="file" multiple accept=".fastq, .gz" ref={el => fileRef.current['fastq'] = el} />
            <button type="submit" onClick={fileSubmit} >Start Upload</button>
            {fastqName === '' ? null : fastqName.length >= 2 ? `${fastqName.length} Files...` : fastqName[0].name}
            <Button className="sumbit" type="submit" onClick={fileSubmit} >Start Upload</Button> */}
                {/* <p className='helpful' >Contact us at support@bioinfox.com to upload Fastq files</p>
            </div>
            <br></br> */}
            {/* <br></br> */}
            {/* <h3 style={{ fontFamily: "Avenir", fontWeight: 'bold' }}>Upload Fasta Sequence File to Pipeline</h3> */}
            {/* <input className="input_seq" type="file" accept=".fasta, .fas, .fa" ref={el => fileRef.current['sequences'] = el} onChange={onChangeSeq} />
            <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['sequences'].click()} >Choose Fasta File</Button>
            {fastaName === '' ? null : fastaName.name}
            <Button className="submit" type="submit" onClick={fileSeqSubmit} >Start Upload</Button> */}
            {/* <p className='helpful' >Contact us at support@bioinfox.com to upload Fasta files</p> */}
            {/* <button type="submit" onClick={fileSeqSubmit} >Start Upload</button> */}
            {/* <br></br>
            <br></br> */}
            <h3 style={{ fontFamily: "Avenir", fontWeight: 'bold' }}>Upload Sample Metadata File</h3>
            <input className="input_client" type="file" accept=".xls, .xlsx" ref={el => fileRef.current['client'] = el} onChange={onChangeClient} />
            <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['client'].click()} >Choose File</Button>
            {clientName === '' ? null : clientName.name}
            <Button className="submit" type="submit" onClick={fileClientSubmit} >Start Upload</Button>
            {/* <button type="submit" onClick={fileClientSubmit} >Start Upload</button> */}
            {/* <p>Download Template File</p> */}
            <br></br>
            <br></br>
            {/* <h3 style={{ fontFamily: "Avenir", fontWeight: 'bold' }}>Upload Sample Metadata File (Custom)</h3>
            <input className="input_add" type="file" accept=".csv, .xls, .xlsx" ref={el => fileRef.current['add'] = el} onChange={onChangeAdd} />
            <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['add'].click()} >Choose File</Button>
            {addName === '' ? null : addName.name}
            <Button className="submit" type="submit" onClick={fileAddSubmit} >Start Upload</Button>
            <br></br>
            <br></br> */}
            {/* <br></br> */}
            <h3 style={{ fontFamily: "Avenir", fontWeight: "bold" }} >Upload Sequence Metadata</h3>
            <input className="input_seq_meta" type="file" accept=".xls, .xlsx" ref={el => fileRef.current['seqMeta'] = el} onChange={onChangeSeqMeta} />
            <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['seqMeta'].click()} >Choose File</Button>
            {seqMeta === '' ? null : seqMeta.name}
            <Button className="submit" type="submit" onClick={fileSeqMetaSubmit} >Start Upload</Button>
            <br />
            <br />
            <h3 style={{ fontFamily: "Avenir", fontWeight: "bold" }} >Upload Production Metadata</h3>
            <input className="input_prod_meta" type="file" accept=".xls, .xlsx" ref={el => fileRef.current['prodMeta'] = el} onChange={onChangeProdMeta} />
            <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['prodMeta'].click()} >Choose File</Button>
            {prodMeta === '' ? null : prodMeta.name}
            <Button className="submit" type="submit" onClick={fileProdMetaSubmit} >Start Upload</Button>
            <br />
            <br />
            <h3 style={{ fontFamily: "Avenir", fontWeight: "bold" }} >Upload Analysis Metadata</h3>
            <input className="input_ana_meta" type="file" accept=".xls, .xlsx" ref={el => fileRef.current['anaMeta'] = el} onChange={onChangeAnaMeta} />
            <Button className="upload-btn" variant="primary" onClick={() => fileRef.current['anaMeta'].click()} >Choose File</Button>
            {anaMeta === '' ? null : anaMeta.name}
            <Button className="submit" type="submit" onClick={fileAnaMetaSubmit} >Start Upload</Button>
            {/* {showProgress ?
                <ProgressBar className="progress_bar" now={progress} label={`${progress}%`} /> : null
            } */}
        </div>
    )
}

export default UploadFiles;