import React from 'react'
import './Copyright.css'

function Copyright() {
    return (
        <div>
            <div className="copyright">The BIE Cloud® service mark and logo are registered trademarks owned by BioInfoExperts LLC</div>
        </div>
    )
}

export default Copyright
