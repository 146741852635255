import { getUrls } from './pages/Pages';
import { checkFileFasta, checkFileTre, showAdd, showStandard, showSequence, checkFileFiltered, showProduction, showAnalysis } from './components/Visualizations/DownloadFiles/DownloadFiles';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { showAddBac, showSequenceBac, showStandardBac } from './components/Visualizations/DownloadFiles/DownloadBacteria';

const fileDownload = require('js-file-download');

// const baseUrl = "https://kxawxoywtd.execute-api.us-east-2.amazonaws.com/dev"  // bie-dev
const baseUrl = "https://aspygs4730.execute-api.us-east-2.amazonaws.com/dev"    // bie-prod


let hospital = 'hospital1';
const getProject = (input, token) => {
    // console.log(input);
    if (input === null) return
    hospital = input.name;
    // getLinks(hospital, token);
}

const getPermissions = (token, func) => {
    let config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    let url = `${baseUrl}/get_permissions`
    axios.get(url, config)
        .then(res => {
            func(res.data);
        })
        .catch(err => {
            console.error(err);
        })
}

const sHospHelp = (hosp) => {
    let store = hosp.split('-');
    // console.log(store)
    return store[0];
}


const getLinks = async (hospital, token) => {
    // console.log(token)
    let config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: {
            project: hospital
        }
    };
    // console.log(config);
    let url = `${baseUrl}/links`
    await axios.get(url, config)
        .then(res => {
            // console.log(res)
            // console.log(res.data)
            getUrls(res.data);
        })
        .catch(err => {
            console.error(err);
            // console.error(err.response.status)
            // logError('FoxSeq-logging', 'tableau-link', err)
        })
}

const uploadFiles = (file, token, bucket, key) => {
    // console.log(file);
    let config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': file[0].type
        },
        params: {
            hospital: hospital,
            bucket: bucket,
            key: key,
            'file_name': key.split('/')[1]
        }
    }
    // console.log(config)
    let url = `${baseUrl}/file_upload` // need to figure out the upload end point
    // let wb = XLSX.read(file);
    // let fileContent = JSON.stringify(wb)
    // let blob = new Blob([file], { type: file[0].type })
    // console.log(blob)
    axios.post(url, file[0], config)
        .then(res => {
            // console.log(res);
        })
        .catch(err => {
            console.error(err);
            // logError('FoxSeq-logging', 'upload', err);
        })
}

const uploadLargeFiles = (file, token, bucket, key) => {
    // console.log(file);
    let config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': file[0].type
        },
        params: {
            project: hospital,
            bucket: bucket,
            key: key,
            'file_name': key.split('/')[1]
        }
    }
    // console.log(config);
    let url = `${baseUrl}/file_upload`;
    axios.get(url, config)
        .then(res => {
            // console.log(res);
            let preUrl = res.data.url;
            // console.log(res.data);
            // console.log(res.data.url)
            // console.log(file);
            // res.data.fields['content-type'] = file[0].type
            axios.put(preUrl, file[0])
                .then(res => {
                    // console.log(res);
                })
                .catch(err => {
                    console.error(err);
                })
        })
        .catch(err => {
            if (err.response) {
                console.error(err.response.data);
            }
            console.error(err);
        })
}



/* ############################ Download Helper Functions ############################# */

const downloadFiles = (project, token, bucket, key, sub) => {
    let config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: {
            project: project,
            bucket: bucket,
            key: key,
            sub: sub
        }
    }
    // console.log(config)
    let url = `${baseUrl}/file_download`
    axios.get(url, config)
        .then(res => {
            // console.log(res.headers['content-length']);
            if (key.includes('fasta')) {
                if (res.headers['content-length'] >= 6000000) {
                    let preUrl = res.data;
                    axios.get(preUrl, { headers: { 'Content-type': 'application/fasta' } })
                        .then(res => {
                            fileDownload(res.data, key)
                        })
                } else {
                    fileDownload(res.data, key)
                }
            } else {
                fileDownload(res.data, key)
            }
        })
        .catch(err => {
            console.error(err);
        })
}

const downloadMeta = (project, token, bucket, key) => {
    let sHosp = sHospHelp(project)
    let config = {
        headers: {
            Authorization: `Bearer ${token}`
        }, 
        params: {
            project,
            bucket,
            key
        }
    }
    let url = `${baseUrl}/meta_download`;
    axios.get(url, config)
        .then(res => {
            // console.log(res);
            const wb = XLSX.read(res.data, { cellDates: true });
            let book = XLSX.write(wb, { bookType: 'xlsx', type: 'array', compression: true, cellDates: true, dateMF: 'yyyy-mm-dd' });
            if (key === 'sample') {
                fileDownload(book, `SAMPLE_METADATA_${sHosp}.xlsx`)
            } else if (key === 'sequence') {
                fileDownload(book, `SEQUENCE_METADATA_${sHosp}.xlsx`)
            } else if (key === 'production') {
                fileDownload(book, `PRODUCTION_METADATA_${sHosp}.xlsx`)
            } else {
                fileDownload(book, `ANALYSIS_METADATA_${sHosp}.xlsx`)
            }
        })
        .catch(err => {
            console.error(err);
        })
}

// template parameter should have string to determine which template file to get
const getTemplates = async (hospital, token, template, bucket) => {
    let config = {
        headers: {
            Authorization: `Bearer ${token}`,
            responseType: 'arrayBuffer',
            'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        params: {
            hospital: hospital,
            template: template,
            bucket: bucket
        }
    }
    // console.log(config);
    let url = `${baseUrl}/gettemplate`
    await axios.get(url, config)
        .then(res => {
            // console.log(res);
            // const wb = XLSX.read(res.data, { cellDates: true })
            // // console.log(wb);
            // let test = XLSX.write(wb, { bookType: 'xlsx', type: 'array', compression: true, cellDates: true })
            if (template === 'sample') {
                // fileDownload(test, "SAMPLE_METADATA_template.xlsx");
            } else if (template === 'sequence') {
                // fileDownload(test, "SEQUENCE_METADATA_template.xlsx")
            }
        })
        .catch(err => {
            console.error(err)
        })
}

const checkFile = async (project, token, func, sub) => {
    let config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: {
            project: project,
            sub: sub
        }
    };
    // console.log(config);
    let url = `${baseUrl}/check_files`;
    await axios.get(url, config)
        .then(res => {
            // console.log(res.data);
            let boolObj = res.data;
            checkFileFasta(boolObj['fasta']);
            checkFileTre(boolObj['tre']);
            checkFileFiltered(boolObj['filtered']);
            func(false);
        })
        .catch(err => {
            console.error(err);
        })
}

const checkMeta = async (project, token, func, sub) => {
    let config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: {
            project
        }
    }
    let url = `${baseUrl}/check_meta`;
    await axios.get(url, config)
        .then(res => {
            let boolObj = res.data;
            // showAdd(boolObj['custom']);
            showStandard(boolObj['sample']);
            showSequence(boolObj['sequence']);
            showProduction(boolObj['production']);
            showAnalysis(boolObj['analysis']);
            func(false);
        })
        .catch(err => {
            console.error(err)
        })
} 

const downloadList = async (project, token, func, sub) => {
    let config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: {
            project,
            sub
        }
    }
    let url = `${baseUrl}/list_download`;
    await axios.get(url, config)
        .then(res => {
            // console.log(res.data)
            func(res.data);
        })
        .catch(err => {
            console.error(err);
        })
}

const checkFileBac = async (hospital, token) => {
    let config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: {
            hospital: hospital
        }
    }
    let url = `${baseUrl}/checkfilebacteria`;
    await axios.get(url, config)
        .then(res => {
            // console.log(res.data);
            let boolObj = res.data;
            showAddBac(boolObj['custom']);
            showStandardBac(boolObj['sample']);
            showSequenceBac(boolObj['sequence']);
        })
        .catch(err => {
            console.error(err);
        })
}

/*######################################## Bacteria Download Helpers ############################################## */



export {
    getProject,
    getLinks,
    sHospHelp,
    getTemplates,
    checkFile,
    downloadFiles,
    uploadFiles,
    uploadLargeFiles,
    checkFileBac,
    downloadMeta,
    checkMeta,
    getPermissions,
    downloadList
}