import React, { useState, useEffect, useRef } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import userIcon from '../../assets/user-icon.svg';
import './User.css';
import { useAuth0 } from '@auth0/auth0-react'
import ProjectMenu from './ProjectMenu';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

let userPerm = ['admin:P2-Testing:Testing']

function User({ logout, projectSelected, resetViz, permissions }) {
  const { user, getAccessTokenSilently } = useAuth0()
  // console.log(user);
  let testArr = []
  // if (permissions < 1) {
  //   let demo = { name: 'P1-Demo', label: 'Demo', admin: false }
  //   testArr.push(demo);
  // } else {
    for (let i = 0; i < permissions.length; i++) {
        let tempObj = {}
        tempObj['name'] = permissions[i].split(':')[1];
        tempObj.label = permissions[i].split(':')[2];
        tempObj.sub = permissions[i].split(':')[3];
        if (permissions[i].split(':')[0] === 'admin') {
            tempObj.admin = true
        } else {
            tempObj.admin = false
        }
        testArr.push(tempObj);
        // setRole(testArr[0])
    // }
  }
  
  // console.log(testArr.length)
  testArr.sort((a, b) => {
    let valA = parseInt(a.name.slice(8))
    let valB = parseInt(b.name.slice(8))
    // console.log(valA, valB);
    return valA - valB
  })
  // console.log(testArr)
  const [role, setRole] = useState(testArr[0]);

  const prevRole = usePrevious(role);
  const prevPerm = usePrevious(permissions)
  useEffect(() => {
    if (prevRole !== role) {
      projectSelected(role);
    } 
    // else if (prevPerm !== permissions) {
    //   setRole(testArr[0])
    //   projectSelected(role)
      
    // }
    // resetViz()
  }, [projectSelected, role, prevRole, permissions, prevPerm, testArr]);

  const switchProject = (selection) => {
    // console.log('User.js: ' + selection)
    setRole(selection);
    // resetViz();
    // projectSelected(selection);
  }

  // console.log(user);

  return (
    <div className="userInfo">
      <ProjectMenu
        roles={testArr.length >= 1 ? testArr : [{ name: 'PX-Home', label: 'Home', admin: false }]}
        switchProject={switchProject}
        resetViz={resetViz}
      />
      <Popup
        trigger={<img src={userIcon} alt="user-icon" className="user-icon" />}
        content={
          <div>
            <Button
              onClick={logout}
              color="red"
              content="Log Out"
            />
          </div>
        }
        on="click"
      />
      <div className="project-info" >
        {user.email}
        <br />
        {role ? `Project: ${role.label}` : ''}
        <br />
        {role ? `Sub-Project: ${role.sub}` : ''}
      </div>
    </div>
  )
}

export default User
