import React from 'react'
import Button from 'react-bootstrap/Button';
import './DownloadFiles.css';

function DowloadList({available, downloadFilesAll}) {
    // console.log(typeof(available))
    // console.log(available)
    return (
        <div>
            <h3 className="title">Download Project Analysis Files</h3>
            <div >
                {
                    available.map(item => {
                        return (
                            <div style={{margin: '2px'}}>
                                <Button variant='primary' onClick={() => downloadFilesAll('output', item)}>{item}</Button>
                                <br></br>
                            </div>
                            
                        )
                    })
                }
            </div>
        </div>
    )
}

export default DowloadList